import { gql } from "apollo-boost"

export const pageMixin = {
    methods:{
        getPages(options){
            const getPages = gql`
            query getPagesForAdmin($options: PaginationInput!){
                getPagesForAdmin(options: $options){
                    pages{
                        _id
                        title
                    }
                    pagination{
                        total
                        page
                    }
                }
            }
            `;
            return this.$apollo.query({
                query: getPages,
                variables:{
                    options
                }
            }).then(response => {
                return response.data.getPagesForAdmin
            })
        },
        getPageDetails(id){
            const getPageDetails = gql`
            query getPageDetailForAdmin($id: ID!){
                getPageDetailForAdmin(_id: $id){
                    page{
                        _id
                        title
                        content
                    }
                }
            }
            `;
            return this.$apollo.query({
                query: getPageDetails,
                variables:{
                    id
                },
                fetchPolicy: 'network-only'
            }).then(response => {
                return response.data.getPageDetailForAdmin
            }).catch( err => {
                console.log(err)
            })
        },
        updatePage(pageData){
            const updatePage = gql`
            mutation savePageForAdmin($pageData: PageInput!){
                savePageForAdmin(request: $pageData){
                    message{
                        message
                        code
                    }
                }
            }
            `;
            return this.$apollo.mutate({
                mutation: updatePage,
                variables:{
                    pageData
                }
            }).then(response => {
                return response.data.savePageForAdmin
            })
        }
    }
}
