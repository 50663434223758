<template>
  <div>
    <vx-card class="mb-2" v-show="setting">
      <p class="ml-5">
        <b style="color: #e11e26">Cautions:-</b> Change to these value change
        all pages style.
      </p>
      <!-- <vs-chip> Change to these value change all pages style. </vs-chip> -->

      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="fontFamily">Font Family</label>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="fontFamily"
              v-model="pageSettings.fontFamily"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("fontFamily")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="fontSize">Font Size</label>
            <vs-select class="w-full" v-model="pageSettings.fontSize">
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in fontSizeOptions"
              />
            </vs-select>
            <span class="text-danger text-sm">{{
              errors.first("fontSize")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="textAlign">Text Align</label>
            <vs-select v-model="pageSettings.textAlign" class="w-full">
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in alignOptions"
              />
            </vs-select>
            <span class="text-danger text-sm">{{
              errors.first("textAlign")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="lineHeight">Line Height</label>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="lineHeight"
              type="number"
              v-model="pageSettings.lineHeight"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("lineHeight")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="colorCode">Font Color</label>

            <input
              type="color"
              v-validate="'required'"
              name="colorCode"
              class="w-full h-10"
              data-vv-validate-on="blur"
              v-model="pageSettings.colorCode"
              value="#0a0a0a "
            />

            <span class="text-danger text-sm">{{
              errors.first("colorCode")
            }}</span>
          </div>
        </vs-col>
        <!-- commented for later use if required -->
        <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="topPadding">Top Padding</label>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="topPadding"
              v-model="pageSettings.topPadding"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("topPadding")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="rightPadding">Right Padding</label>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="rightPadding"
              v-model="pageSettings.rightPadding"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("rightPadding")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="bottomPadding">Bottom Padding</label>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="bottomPadding"
              v-model="pageSettings.bottomPadding"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("bottomPadding")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="leftPadding">Left Padding</label>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="leftPadding"
              v-model="pageSettings.leftPadding"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("leftPadding")
            }}</span>
          </div>
        </vs-col> -->
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="topMargin">Top Margin</label>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="topMargin"
              type="number"
              v-model="pageSettings.topMargin"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("topMargin")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="rightMargin">Right Margin</label>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="rightMargin"
              type="number"
              v-model="pageSettings.rightMargin"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("rightMargin")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="bottomMargin">Bottom Margin</label>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="bottomMargin"
              type="number"
              v-model="pageSettings.bottomMargin"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("bottomMargin")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="leftMargin">Left Margin</label>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="leftMargin"
              type="number"
              v-model="pageSettings.leftMargin"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("leftMargin")
            }}</span>
          </div>
        </vs-col>
      </vs-row>
      <vs-row class="flex flex-wrap items-center p-6">
        <vs-button
          class="mr-6"
          color="danger"
          type="flat"
          @click="settingStatus()"
          >Cancel</vs-button
        >
        <vs-button color="primary" @click="changeSetting"
          >Save Settings</vs-button
        >
      </vs-row>
    </vx-card>
    <vx-card style="padding-left: 10px" v-show="!setting">
      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
        >
          <template slot="header">
            <!-- <vx-tooltip text="Change Style of Pages">
              <vs-button
                class="mx-1 md:mb-0"
                color="primary"
                @click="settingStatus()"
                >Style Pages
              </vs-button>
            </vx-tooltip> -->
          </template>
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
            <div
              class="flex flex-wrap-reverse items-center data-list-btn-container"
            ></div>
            <v-select
              v-model="dataTableParams.limit"
              class="mr-4"
              :options="limitOptions"
              :clearable="false"
            />
          </div>

          <template slot="thead">
            <vs-th sort-key="title">Page Titlee</vs-th>
            <vs-th sort-key="createdAt">Created At</vs-th>
            <vs-th sort-key="updatedAt">Updated At</vs-th>
            <vs-th sort-key="action" size="25%">Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].title">{{
                data[indextr].title
              }}</vs-td>
              <vs-td :data="data[indextr].createdAt">{{
                moment(data[indextr].createdAt).format("DD/MM/YYYY")
              }}</vs-td>
              <vs-td :data="data[indextr].updatedAt">{{
                moment(data[indextr].updatedAt).format("DD/MM/YYYY")
              }}</vs-td>
              <vs-td :data="data[indextr]._id">
                <vs-row vs-type="flex">
                  <vx-tooltip text="Edit" position="top" delay=".3s">
                    <!-- <vs-button
                    type="gradient"
                    size="small"
                    @click="editDetailHandler(data[indextr]._id)"
                    icon-pack="feather"
                    icon="icon-edit"
                    color="warning"
                  ></vs-button> -->
                    <feather-icon
                      icon="Edit3Icon"
                      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                      @click="editDetailHandler(data[indextr]._id)"
                    />
                  </vx-tooltip>
                  <!-- <vs-button type="border" size="small" icon-pack="feather" icon="icon-trash" color="danger"></vs-button> -->
                </vs-row>
                <!-- <vs-button type="border" size="small" icon-pack="feather" icon="icon-send" color="success" class="mr-2"></vs-button> -->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <span class="mr-2"
          >{{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}</span
        >
        <vs-pagination
          :total="totalPage"
          v-model="dataTableParams.page"
        ></vs-pagination>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";
import { pageMixin } from '../../mixins/pageMixin'

export default {
  components: {
    "v-select": vSelect,
  },
  mixins: [pageMixin],
  data() {
    return {
      setting: false,
      pageSettings: {
        slug: "setting1", //for pages css
        fontFamily: "ProximaNova",
        fileName: "xyz",
        fontSize: "16",
        lineHeight: "1.4",
        colorCode: "#2B2D3D",
        textAlign: "left",
        topPadding: 0,
        rightPadding: 0,
        bottomPadding: 0,
        leftPadding: 0,
        topMargin: 0,
        rightMargin: 0,
        bottomMargin: 0,
        leftMargin: 0,
      },
      alignOptions: ["left", "center", "right", "justify"],
      fontSizeOptions: [8, 9, 10, 11, 12, 14, 16, 18, 20],

      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 30,
      },
      users: [],
      limitOptions: [30, 50, 100, 150],
    };
  },
  methods: {
    ...mapActions("page", [
      "fetchPages",
      "savePagesSettings",
      "getPagesSettings",
    ]),
    moment(date) {
      return moment(date);
    },
    settingStatus() {
      this.setting = !this.setting;
      if (this.setting) {
        this.getPagesSettings(this.pageSettings.slug).then((res) => {
          this.pageSettings = res.data.data;

          this.pageSettings.topMargin = res.data.data.margin[0];
          this.pageSettings.rightMargin = res.data.data.margin[1];
          this.pageSettings.bottomMargin = res.data.data.margin[2];
          this.pageSettings.leftMargin = res.data.data.margin[3];

          this.pageSettings.topPadding = res.data.data.padding[0];
          this.pageSettings.rightPadding = res.data.data.padding[1];
          this.pageSettings.bottomPadding = res.data.data.padding[2];
          this.pageSettings.leftPadding = res.data.data.padding[3];
        });
      }
    },
    changeSetting() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            slug: this.pageSettings.slug,
            fontFamily: this.pageSettings.fontFamily,
            fontSize: this.pageSettings.fontSize,
            fileName: this.pageSettings.fileName,
            colorCode: this.pageSettings.colorCode,
            textAlign: this.pageSettings.textAlign,
            lineHeight: this.pageSettings.lineHeight,
            margin: [
              parseInt(this.pageSettings.topMargin),
              parseInt(this.pageSettings.rightMargin),
              parseInt(this.pageSettings.bottomMargin),
              parseInt(this.pageSettings.leftMargin),
            ],
            padding: [
              parseInt(this.pageSettings.topPadding),
              parseInt(this.pageSettings.rightPadding),
              parseInt(this.pageSettings.bottomPadding),
              parseInt(this.pageSettings.leftPadding),
            ],
          };

          this.savePagesSettings(data).then((res) => {
            this.pageSettings = res.data.data;

            this.pageSettings.topMargin = res.data.data.margin[0];
            this.pageSettings.rightMargin = res.data.data.margin[1];
            this.pageSettings.bottomMargin = res.data.data.margin[2];
            this.pageSettings.leftMargin = res.data.data.margin[3];

            this.pageSettings.topPadding = res.data.data.padding[0];
            this.pageSettings.rightPadding = res.data.data.padding[1];
            this.pageSettings.bottomPadding = res.data.data.padding[2];
            this.pageSettings.leftPadding = res.data.data.padding[3];

            this.$vs.notify({
              title: "Page Style Updated",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.setting = false;
          });
        }
      });
    },
    getPagesList() {
      let self = this;
      this.getPages(self.dataTableParams).then((res) => {
        self.users = res.pages;
        self.totalDocs = res.pagination.total;
        self.page = res.pagination.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getPagesList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getPagesList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getPagesList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: "CMSPageDetails", params: { id: id } });
    },
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.pageSettings.fontFamily != "" &&
        this.pageSettings.fontSize != "" &&
        this.pageSettings.textAlign != "" &&
        this.pageSettings.lineHeight != "" &&
        this.pageSettings.topMargin != "" &&
        this.pageSettings.rightMargin != "" &&
        this.pageSettings.bottomMargin != "" &&
        this.pageSettings.leftMargin != "" &&
        this.pageSettings.colorCode != ""
      );
    },
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getPagesList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getPagesList();
      }
    },
  },
  created() {
    this.getPagesList();
  },
};
</script>
